import React, { useState, useEffect } from "react";
import "../css/LandingPage.css";
import Carousel from "react-bootstrap/Carousel";
import Events from "./Events";
import Sponsorship from "./sponsorship";
import { sanitizeInput } from "../services/sanitize";
import imglogo from "../images/cgwc-logo.png";
import { Link } from "react-router-dom";
import "animate.css";
import { Modal, Spinner } from "react-bootstrap";

function importAllImages(r) {
  return r.keys().map(r);
}

const images = importAllImages(
  require.context("../images", false, /\.(png|jpe?g|svg)$/)
);

function LandingPage() {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isClearing, setIsClearing] = useState(false);
  const [clearSuccess, setClearSuccess] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const closeNavbar = () => {
    setIsNavbarCollapsed(true);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [isFormValid, setIsFormValid] = useState(true);

  const handleChange = (e) => {
    const { id, value, type } = e.target;
    const sanitizedValue = sanitizeInput(
      value,
      type === "email" ? "email" : "text"
    );

    if (sanitizedValue === null) {
      setError(`Invalid characters detected in the ${id} field.`);
      setIsFormValid(false);
      setFormData((prevData) => ({
        ...prevData,
        [id]: formData[id] || "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: sanitizedValue,
      }));
      setError(null);
      setIsFormValid(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid) {
      setError("Please remove invalid characters before submitting.");
      return;
    }

    if (!formData.name || !formData.email || !formData.message) {
      setError("All fields are required.");
      return;
    }

    setFormData({ name: "", email: "", message: "" });
    setError(null);
    setIsFormValid(true);
  };

  // Function to clear cookies
  const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  };

  // Function to clear local and session storage
  const clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  // Function to clear cache (only works if Service Workers are used)
  const clearCache = async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName))
      );
    }
  };

  // Function to clear site data
  const clearSiteData = async () => {
    setIsClearing(true);
    try {
      clearCookies();
      clearStorage();
      await clearCache();
      setClearSuccess(true);
    } catch (error) {
      console.error("Error clearing site data:", error);
      setClearSuccess(false);
    } finally {
      setShowModal(true);
      setIsClearing(false);
    }
  };

  // Countdown timer effect
  useEffect(() => {
    let timer;
    if (showModal && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (showModal && countdown === 0) {
      setShowModal(false);
      setCountdown(5); // Reset countdown for next time
    }
    return () => clearTimeout(timer);
  }, [showModal, countdown]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-white shadow-sm">
        <div className="container">
          {/* use img imglogo instead */}
          <a className="navbar-brand" href="/">
            <img src={imglogo} alt="CGWC Logo" style={{ height: "40px" }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarNav"
            aria-expanded={!isNavbarCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              isNavbarCollapsed ? "" : "show"
            }`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a
                  className="nav-link text-dark"
                  href="#about"
                  onClick={closeNavbar}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark"
                  href="#features"
                  onClick={closeNavbar}
                >
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark"
                  href="#events"
                  onClick={closeNavbar}
                >
                  Events
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark"
                  href="#contact"
                  onClick={closeNavbar}
                >
                  Contact
                </a>
              </li>
              {/* Troubleshoot Nav Link */}
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link text-dark"
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    closeNavbar();
                    clearSiteData();
                  }}
                >
                  Troubleshoot
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header className="hero-section mt-5 pt-3">
        <Carousel className="hero-carousel">
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                src={image}
                className="d-block w-100 hero-carousel-image"
                alt={`Slide ${index}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="hero-content text-light bg-dark bg-opacity-50 p-4">
          <h1 className="display-4">Welcome to CGWC Portal</h1>
          <p className="lead">
            Your ultimate gateway to managing and accessing resources seamlessly
          </p>
        </div>
      </header>

      <section id="about" className="py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="text-primary">About CGWC Portal</h2>
              <p className="text-secondary">
                The CGWC Portal is designed to streamline access to various
                resources and provide a seamless experience for our users.
                Whether you're looking to manage projects or stay updated, the
                portal has got you covered.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h2 className="text-primary">Features</h2>
            <p className="text-muted">
              Discover what makes CGWC Portal the best solution for you.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm text-center bg-light">
                <div className="card-body">
                  <i
                    className="bi bi-tree-fill mb-3 text-success"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h5 className="card-title">Camp Activities</h5>
                  <p className="card-text">
                    Engage in various camp activities designed to build skills,
                    friendships, and fun.
                  </p>
                  <Link
                    to="/camp-activities"
                    className="btn mt-3"
                    style={{ backgroundColor: "#042556", color: "#fff" }}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm text-center bg-light">
                <div className="card-body">
                  <i
                    className="bi bi-eye-fill mb-3 text-secondary"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h5 className="card-title">Visions</h5>
                  <p className="card-text">
                    Share and develop visions that inspire and guide the
                    community's future.
                  </p>
                  <a
                    href="#visions"
                    className="btn mt-3"
                    style={{ backgroundColor: "#042556", color: "#fff" }}
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm text-center bg-light">
                <div className="card-body">
                  <i
                    className="bi bi-camera-video-fill mb-3 text-primary"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <h5 className="card-title">Virtual Meetings</h5>
                  <p className="card-text">
                    Participate in online meetings and stay connected from
                    anywhere.
                  </p>
                  <a
                    href="#virtual-meetings"
                    className="btn mt-3"
                    style={{ backgroundColor: "#042556", color: "#fff" }}
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="events">
        <Events />
      </section>

      <Sponsorship />

      <section id="contact" className="py-5 bg-light">
        <div className="container">
          <div className="text-center mb-5">
            <h2 className="text-primary">Contact Us</h2>
            <p className="text-muted">
              Get in touch with us for more information or assistance.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form onSubmit={handleSubmit} noValidate>
                {error && <div className="alert alert-danger">{error}</div>}

                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      error && formData.name === "" ? "is-invalid" : ""
                    }`}
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      error && formData.email === "" ? "is-invalid" : ""
                    }`}
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className={`form-control ${
                      error && formData.message === "" ? "is-invalid" : ""
                    }`}
                    id="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="btn"
                  style={{ backgroundColor: "#042556", color: "#fff" }}
                  disabled={!isFormValid}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Troubleshoot Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#042556", color: "#fff" }}
        >
          <Modal.Title>Troubleshoot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isClearing ? (
            <div className="text-center">
              <Spinner animation="border" role="status" />
              <p className="mt-3">Clearing site data...</p>
            </div>
          ) : clearSuccess ? (
            <div className="text-center">
              <p>Site data cleared successfully!</p>
              <p>
                Closing in {countdown} second{countdown !== 1 ? "s" : ""}...
              </p>
            </div>
          ) : (
            <div className="text-center">
              <p>Failed to clear site data. Please try again.</p>
              <p>
                Closing in {countdown} second{countdown !== 1 ? "s" : ""}...
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <footer
        className="text-light text-center py-4"
        style={{ backgroundColor: "#042556" }}
      >
        <div className="container">
          <p className="mb-0">&copy; 2024 CGWC Portal. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
