// src/components/Events.jsx

import React, { useState } from "react";
import {
  Modal,
  Button,
  Card,
  Row,
  Col,
  Container,
  Collapse,
} from "react-bootstrap";
import {
  ArrowDown,
  ArrowUp,
  PersonFill,
  PeopleFill,
} from "react-bootstrap-icons";
import EventRegistrationForm from "./EventRegistrationForm";
import AssemblyRegistrationForm from "./AssemblyRegistrationForm";
import Countdown from "./Countdown"; // Import the Countdown component
import youthCamps from "../eventsimg/youthcamp2024.jpg"; // Image import

const eventsData = [
  {
    img: youthCamps,
    title: "CGWC YOUTH CAMP 2024",
    shortDescription:
      'In today\'s fast-paced world, the "Spirit of Age" often pulls us toward conformity, distractions, and compromise...',
    fullDescription:
      'In today\'s fast-paced world, the "Spirit of Age" often pulls us toward conformity, distractions, and compromise. The CGWC Youth Camp 2024 is an immersive experience aimed at inspiring youth to stay true to their beliefs and strengthen their bond with their community and faith through engaging activities, workshops, and discussions.',
    targetDate: "December 18, 2024 00:00:00", // Added targetDate
    registrable: {
      status: true,
      formData: {
        fields: [
          { label: "First Name", type: "text" },
          { label: "Last Name", type: "text" },
          { label: "Email", type: "email" },
          { label: "Phone Number", type: "tel" },
        ],
        payment: { status: true, amount: 3000 },
      },
    },
  },
];

function Events() {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [expandedEventIndex, setExpandedEventIndex] = useState(null);
  const [registrationType, setRegistrationType] = useState(null);

  const handleRegisterClick = (event) => {
    setSelectedEvent(event);
    setRegistrationType(null); // Reset registration type
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setRegistrationType(null); // Reset registration type
  };

  const toggleDescription = (index) => {
    setExpandedEventIndex(expandedEventIndex === index ? null : index);
  };

  return (
    <section className="events-section py-5">
      <Container>
        <h2 className="text-center mb-4 text-primary">Upcoming Events</h2>
        <Row xs={1} md={2} lg={3} className="g-4">
          {eventsData.map((event, index) => {
            const isExpanded = expandedEventIndex === index;

            return (
              <Col key={index}>
                <Card className="h-100 shadow-sm border-0">
                  <div className="position-relative">
                    <Card.Img
                      variant="top"
                      src={event.img}
                      alt={event.title}
                      className="rounded-top card-img-custom"
                    />
                  </div>
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title className="text-secondary">
                      {event.title}
                    </Card.Title>

                    {/* Countdown Timer */}
                    <Countdown targetDate={event.targetDate} />

                    <Collapse in={isExpanded}>
                      <div>
                        <Card.Text>{event.fullDescription}</Card.Text>
                      </div>
                    </Collapse>
                    {!isExpanded && (
                      <Card.Text>{event.shortDescription}</Card.Text>
                    )}
                    <div className="d-flex flex-column flex-md-row gap-2 mt-2">
                      <Button
                        style={{
                          backgroundColor: "#042556",
                          color: "#fff",
                          border: "none",
                        }}
                        className="rounded d-flex align-items-center justify-content-center w-100"
                        onClick={() => toggleDescription(index)}
                      >
                        {isExpanded ? (
                          <>
                            Read Less <ArrowUp className="ms-2" />
                          </>
                        ) : (
                          <>
                            Read More <ArrowDown className="ms-2" />
                          </>
                        )}
                      </Button>
                      {event.registrable.status && (
                        <Button
                          style={{
                            backgroundColor: "#042556",
                            color: "#fff",
                            border: "none",
                          }}
                          className="rounded d-flex align-items-center justify-content-center w-100"
                          onClick={() => handleRegisterClick(event)}
                        >
                          Register Now
                        </Button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>

      {selectedEvent && (
        <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">
              {selectedEvent.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="position-relative text-center">
              <img
                src={selectedEvent.img}
                alt={selectedEvent.title}
                className="img-fluid rounded mb-3 shadow-sm modal-img-custom"
              />
            </div>
            <p>{selectedEvent.fullDescription}</p>

            {/* Countdown Timer inside Modal */}
            <Countdown targetDate={selectedEvent.targetDate} />

            {!registrationType && (
              <div className="text-center">
                <h5>Select Registration Type:</h5>
                <div className="d-flex gap-3 justify-content-center mt-3">
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center gap-2"
                    onClick={() => setRegistrationType("individual")}
                  >
                    <PersonFill />
                    Individual Registration
                  </Button>
                  <Button
                    variant="outline-success"
                    className="d-flex align-items-center gap-2"
                    onClick={() => setRegistrationType("assembly")}
                  >
                    <PeopleFill />
                    Bulk Registration
                  </Button>
                </div>
              </div>
            )}

            {registrationType === "individual" && (
              <EventRegistrationForm
                event={selectedEvent}
                onClose={handleClose}
              />
            )}
            {registrationType === "assembly" && (
              <AssemblyRegistrationForm
                event={selectedEvent}
                onClose={handleClose}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </section>
  );
}

export default Events;
