// src/components/EventRegistrationForm.jsx
import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Form, Modal, Button, Alert, Container } from "react-bootstrap";
import { sanitizeInput } from "../services/sanitize";
import IndividualStepOneForm from "../components/indivisualStepOne";
import ErrorAlert from "./ErrorAlert";
import { memberAreas } from "../services/area";
import useFormStore from "../store/formStore";

function EventRegistrationForm({ event, onClose }) {
  const {
    formData,
    errors,
    validated,
    isSubmitting,
    submitError,
    setFormData,
    setErrors,
    setValidated,
    setSubmitError,
    resetForm,
  } = useFormStore();

  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [whatsappLink, setWhatsappLink] = useState("");

  const { fields } = event.registrable.formData;

  // Define registration amounts
  const registrationOnlyAmount = 3000; // Adjust as needed
  const registrationWithBedAmount = 4500; // Adjust as needed

  // Initialize form data with useMemo
  const initialFormData = useMemo(
    () => ({
      is_member: false,
      member_area_id: "",
      bed_option: "",
      ...fields.reduce((acc, field) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        acc[fieldName] = "";
        return acc;
      }, {}),
    }),
    [fields]
  );

  // Initialize form errors with useMemo
  const initialErrors = useMemo(
    () => ({
      is_member: "",
      member_area_id: "",
      bed_option: "",
      ...fields.reduce((acc, field) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        acc[fieldName] = "";
        return acc;
      }, {}),
    }),
    [fields]
  );

  // Restore state from localStorage on component mount
  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");

    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      setFormData(parsedFormData);
    } else {
      resetForm(initialFormData, initialErrors);
    }
  }, [initialFormData, initialErrors, resetForm, setFormData]);

  // Save state to localStorage whenever formData changes
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  // Form validation logic with real-time validation
  const validateForm = useCallback(() => {
    let valid = true;
    const newErrors = { ...initialErrors };

    fields.forEach((field) => {
      const fieldName = field.label.toLowerCase().replace(/ /g, "_");
      const value = formData[fieldName]?.trim();
      const fieldType = field.type === "email" ? "email" : field.type;
      const sanitizedValue = sanitizeInput(value, fieldType);

      if (!sanitizedValue) {
        valid = false;
        newErrors[
          fieldName
        ] = `Invalid characters detected in the ${field.label} field.`;
      }

      if (
        field.type === "email" &&
        value &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      ) {
        valid = false;
        newErrors[fieldName] = "Invalid email format.";
      }

      if (fieldName === "phone_number" && value && !/^\d{10,15}$/.test(value)) {
        valid = false;
        newErrors[fieldName] = "Invalid phone number format. Use 10-15 digits.";
      }

      // Add required field validation
      if (field.required && !value) {
        valid = false;
        newErrors[fieldName] = `${field.label} is required.`;
      }
    });

    // Validate bed_option
    if (
      !["registration_only", "registration_with_bed"].includes(
        formData.bed_option
      )
    ) {
      valid = false;
      newErrors.bed_option = "Please select a valid registration option.";
    }

    if (formData.is_member === undefined) {
      valid = false;
      newErrors.is_member = "Please select your membership status.";
    }

    if (formData.is_member && formData.member_area_id === "") {
      valid = false;
      newErrors.member_area_id = "Please select your area.";
    }

    setErrors(newErrors);
    return valid;
  }, [fields, formData, initialErrors, setErrors]);

  // Handle input changes with real-time validation
  const handleChange = useCallback(
    (e) => {
      const { name, value, checked } = e.target;
      const inputValue = name === "is_member" ? checked : value;

      setFormData((prevData) => ({ ...prevData, [name]: inputValue }));

      // Real-time validation for specific fields
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        // Validate specific fields on change
        if (name === "email") {
          if (!inputValue) {
            newErrors[name] = "Email is required.";
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue.trim())) {
            newErrors[name] = "Invalid email format.";
          } else {
            delete newErrors[name];
          }
        }

        if (name === "phone_number") {
          if (!inputValue) {
            newErrors[name] = "Phone number is required.";
          } else if (!/^\d{10,15}$/.test(inputValue.trim())) {
            newErrors[name] = "Invalid phone number format. Use 10-15 digits.";
          } else {
            delete newErrors[name];
          }
        }

        if (name === "member_area_id" && formData.is_member) {
          if (!inputValue) {
            newErrors[name] = "Please select your area.";
          } else {
            delete newErrors[name];
          }
        }

        if (name === "bed_option") {
          if (
            !["registration_only", "registration_with_bed"].includes(inputValue)
          ) {
            newErrors.bed_option = "Please select a valid registration option.";
          } else {
            delete newErrors.bed_option;
          }
        }

        return newErrors;
      });
    },
    [formData.is_member, setFormData, setErrors]
  );

  // Handle form submission
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const isValid = validateForm();
      setValidated(true);

      if (isValid) {
        const message = generateWhatsAppMessage();
        const whatsappNumber = "2349012201298"; // WhatsApp number without '+'
        const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
          message
        )}`;
        setWhatsappLink(whatsappLink);

        setShowWhatsAppModal(true);
        setSubmitError(null);
      } else {
        setSubmitError("Please fix the errors before proceeding.");
      }
    },
    [validateForm]
  );

  // Generate WhatsApp message
  const generateWhatsAppMessage = () => {
    let message = `Registration Details:\n`;

    fields.forEach((field) => {
      const fieldName = field.label.toLowerCase().replace(/ /g, "_");
      const fieldLabel = field.label;
      const value = formData[fieldName];
      message += `${fieldLabel}: ${value}\n`;
    });

    message += `Are you a CGWC Member?: ${formData.is_member ? "Yes" : "No"}\n`;

    if (formData.is_member && formData.member_area_id) {
      const selectedArea = memberAreas.find(
        (area) => area.id === formData.member_area_id
      );
      message += `Member Area: ${
        selectedArea ? selectedArea.name : formData.member_area_id
      }\n`;
    }

    // Include Registration Option
    message += `Registration Option: ${
      formData.bed_option === "registration_only"
        ? `Registration Only (₦${registrationOnlyAmount.toLocaleString()})`
        : `Registration with Bed (₦${registrationWithBedAmount.toLocaleString()})`
    }\n`;

    return message;
  };

  // Function to handle closing the modal and resetting the form
  const handleCloseModal = () => {
    setShowWhatsAppModal(false);
    resetForm(initialFormData, initialErrors);
    localStorage.removeItem("formData"); // Clear saved form data
    onClose();
  };

  return (
    <>
      <Container>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {submitError && <ErrorAlert errors={{ submitError }} />}

          <IndividualStepOneForm
            formData={formData}
            errors={errors}
            handleChange={handleChange}
            fields={fields}
            memberAreas={memberAreas}
            requiredLabel={(field) => field.label !== "Are you a member"}
            registrationOnlyAmount={registrationOnlyAmount}
            registrationWithBedAmount={registrationWithBedAmount}
          />

          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </div>
        </Form>
      </Container>

      {/* WhatsApp Modal */}
      <Modal
        show={showWhatsAppModal}
        onHide={() => setShowWhatsAppModal(false)}
        centered
        aria-labelledby="whatsapp-modal-title"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#042556", color: "#fff" }}
        >
          <Modal.Title id="whatsapp-modal-title">
            Registration Step 1 Complete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">
            The first stage of your registration is complete. Kindly click this
            WhatsApp button to submit your registration and follow the steps
            after.
          </Alert>
          <div className="d-flex justify-content-center">
            <Button
              variant="success"
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Submit via WhatsApp"
            >
              Submit via WhatsApp
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCloseModal}
            aria-label="Close Modal"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventRegistrationForm;
