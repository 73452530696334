import React, { useState, useEffect, useCallback } from "react";

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const isEventStarted = Object.keys(timeLeft).length === 0;

  const icons = {
    Days: "bi-calendar3",
    Hours: "bi-hourglass-split",
    Minutes: "bi-clock",
    Seconds: "bi-hourglass-bottom",
  };

  return (
    <div className="container-fluid">
      {!isEventStarted ? (
        <div className="row justify-content-center g-2">
          {Object.keys(timeLeft).map((interval) => (
            <div
              key={interval}
              className="col-6 col-sm-3 text-center bg-light p-3 rounded shadow-sm"
              style={{ minWidth: "80px" }}
            >
              <i className={`bi ${icons[interval]} fs-3 text-primary`}></i>
              <h2 className="mb-0">{timeLeft[interval]}</h2>
              <p className="mb-0 text-muted">{interval}</p>            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-danger fs-4 fw-bold">
          Event Started!
        </div>
      )}
    </div>
  );
};

export default Countdown;
