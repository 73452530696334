// src/components/StepOneForm.jsx

import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import ErrorAlert from "./ErrorAlert";
import PropTypes from "prop-types";

function StepOneForm({
  formData,
  errors,
  handleChange,
  isSubmitting,
  fields,
  memberAreas,
  requiredLabel,
  hideNextButton = false,
  uniqueId,
  registrationOnlyAmount,
  registrationWithBedAmount,
}) {
  return (
    <>
      {Object.values(errors).some((errorMsg) => errorMsg) && (
        <ErrorAlert errors={errors} />
      )}

      {/* Participant Information Fields */}
      <Row>
        {fields.map((field, index) => {
          const fieldName = field.label.toLowerCase().replace(/ /g, "_");
          return (
            <Col xs={12} md={6} key={index}>
              <Form.Group
                controlId={`${fieldName}_${uniqueId}`}
                className="mb-3"
              >
                <Form.Label>
                  {field.label}
                  {requiredLabel(field) && (
                    <span style={{ color: "red" }}> *</span>
                  )}
                </Form.Label>
                <Form.Control
                  type={field.type}
                  name={fieldName}
                  value={formData[fieldName]?.value || ""}
                  onChange={handleChange}
                  isInvalid={!!errors[fieldName]}
                  required
                  placeholder={`Enter ${field.label.toLowerCase()}`}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[fieldName]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        })}
      </Row>

      {/* CGWC Membership Checkbox */}
      <Form.Group controlId={`is_member_${uniqueId}`} className="mb-3">
        <Form.Check
          type="checkbox"
          label="Are you a CGWC Member?"
          name="is_member"
          checked={formData.is_member?.value || false}
          onChange={handleChange}
          isInvalid={!!errors.is_member}
        />
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.is_member}
        </Form.Control.Feedback>
      </Form.Group>

      {/* Member Area Selection (Conditional) */}
      {formData.is_member?.value && (
        <Form.Group controlId={`member_area_id_${uniqueId}`} className="mb-3">
          <Form.Label>Select Your Area</Form.Label>
          <Form.Control
            as="select"
            name="member_area_id"
            value={formData.member_area_id?.value || ""}
            onChange={handleChange}
            isInvalid={!!errors.member_area_id}
            required
          >
            <option value="">-- Select Area --</option>
            {memberAreas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.member_area_id}
          </Form.Control.Feedback>
        </Form.Group>
      )}

      {/* Registration Option for bed_option */}
      <Form.Group controlId={`bed_option_${uniqueId}`} className="mb-3">
        <Form.Label>Registration Option</Form.Label>
        <div>
          <Form.Check
            type="radio"
            label={`Registration Only (₦${registrationOnlyAmount.toLocaleString()})`}
            name="bed_option"
            id={`registration_only_${uniqueId}`}
            value="registration_only"
            checked={formData.bed_option?.value === "registration_only"}
            onChange={handleChange}
            required
          />
          <Form.Check
            type="radio"
            label={`Registration with Bed (₦${registrationWithBedAmount.toLocaleString()})`}
            name="bed_option"
            id={`registration_with_bed_${uniqueId}`}
            value="registration_with_bed"
            checked={formData.bed_option?.value === "registration_with_bed"}
            onChange={handleChange}
            required
          />
        </div>
        {errors.bed_option && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.bed_option}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
}

// Update PropTypes accordingly
StepOneForm.propTypes = {
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  memberAreas: PropTypes.array.isRequired,
  requiredLabel: PropTypes.func.isRequired,
  hideNextButton: PropTypes.bool,
  uniqueId: PropTypes.string.isRequired,
  registrationOnlyAmount: PropTypes.number.isRequired,
  registrationWithBedAmount: PropTypes.number.isRequired,
};

export default StepOneForm;
