// src/components/Sponsorship.jsx

import React, { useState } from "react";
import Sponsor from "../eventsimg/Sponsorship-Blog.png";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { Clipboard } from "react-bootstrap-icons";
import { Form, Modal, Button, Spinner } from "react-bootstrap";

function Sponsorship() {
  const publicKey = "FLWPUBK-c2936c090125d5d8f91439cee2a28420-X"; // Define publicKey here

  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [paymentReference, setPaymentReference] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFlutterwavePayment = useFlutterwave({
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: formData.amount,
    currency: "NGN",
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: formData.email,
      phonenumber: formData.phoneNumber,
      name: formData.name,
    },
    customizations: {
      title: "CGWC Youth Fellowship Sponsorship",
      description: "Support our event through your generous donation",
      logo: "https://cgwc-youth-fellowship.vercel.app/static/media/cgwc-logo.e8cbe010260cca764e57.png",
    },
  });

  const onSuccess = (reference) => {
    setPaymentReference(reference.transaction_id);
    setShowSuccessModal(true);
    setIsProcessing(false);
    setShowModal(false);
    closePaymentModal(); // Close the Flutterwave payment modal
  };

  const onClosePayment = () => {
    // console.log("Payment modal closed without completing payment");
    setShowFailureModal(true);
    setIsProcessing(false);
  };

  const handleSupportNow = () => {
    const { name, email, phoneNumber, amount } = formData;
    if (!name || !email || !phoneNumber || !amount) {
      alert("Please fill in all the required fields.");
      return;
    }

    setIsProcessing(true);

    handleFlutterwavePayment({
      callback: onSuccess,
      onClose: onClosePayment,
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      amount: "",
    });
  };

  const handleCopyRegistrationNumber = () => {
    if (!paymentReference) {
      alert("No payment reference available to copy.");
      return;
    }
    navigator.clipboard
      .writeText(paymentReference)
      .then(() => {
        alert("Payment reference copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        alert("Failed to copy payment reference. Please try again.");
      });
  };

  return (
    <section className="sponsorship-section py-5 bg-light">
      <div className="container d-flex flex-column flex-lg-row justify-content-center align-items-center">
        <div className="card shadow-lg sponsor-card text-center bg-white">
          <img
            src={Sponsor}
            alt="Support Us"
            className="card-img-top"
            style={{ maxHeight: "200px", objectFit: "cover" }}
          />
          <div className="card-body">
            <h5 className="card-title text-primary">Support or Sponsor Us</h5>
            <p className="card-text text-muted">
              Your generous support makes a significant difference. Join us as a
              sponsor or supporter and contribute to a meaningful cause.
            </p>
            <button
              className="btn"
              style={{
                backgroundColor: "#042556",
                color: "#fff",
                border: "none",
              }}
              onClick={() => setShowModal(true)}
            >
              Support Us
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#042556", color: "#fff" }}
        >
          <Modal.Title>Support Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>
                Name <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>
                Email <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhoneNumber">
              <Form.Label>
                Phone Number <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAmount">
              <Form.Label>
                Amount (₦) <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                min="1"
                onWheel={(e) => e.target.blur()}
                required
              />
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={isProcessing}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSupportNow}
                disabled={
                  isProcessing ||
                  !formData.name ||
                  !formData.email ||
                  !formData.phoneNumber ||
                  !formData.amount
                }
              >
                {isProcessing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Support Now"
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#28a745", color: "#fff" }}
        >
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your generous contribution is greatly appreciated. Thank you for
            your support!
          </p>
          <p>
            Your Payment Reference: <strong>{paymentReference}</strong>
          </p>
          <div className="d-flex align-items-center mb-3">
            <strong>Payment Reference: </strong>
            <span className="ms-2">{paymentReference}</span>
            <Button
              variant="link"
              onClick={handleCopyRegistrationNumber}
              className="ms-2 p-0"
              aria-label="Copy Payment Reference"
            >
              <Clipboard size={18} />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#dc3545", color: "#fff" }}
        >
          <Modal.Title>Payment Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We encountered an issue with your payment. Please try again, or
            contact us if the issue persists.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowFailureModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Sponsorship;
