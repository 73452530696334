// src/components/ErrorAlert.jsx
import React from "react";
import { Alert } from "react-bootstrap";

function ErrorAlert({ errors }) {
  // Group errors by participant index
  const participantErrors = {};
  const generalErrors = [];

  Object.entries(errors).forEach(([field, errorMsg]) => {
    if (errorMsg) {
      const participantMatch = field.match(/_(\d+)$/); // Matches the index at the end
      if (participantMatch) {
        const index = participantMatch[1];
        if (!participantErrors[index]) {
          participantErrors[index] = [];
        }
        const fieldName = field.replace(/_\d+$/, ""); // Remove the index from field name
        participantErrors[index].push({ field: fieldName, message: errorMsg });
      } else {
        generalErrors.push({ field, message: errorMsg });
      }
    }
  });

  return (
    <Alert variant="danger" className="shadow-sm">
      <Alert.Heading className="mb-2">Error Notification</Alert.Heading>
      <p className="mb-1">Please fix the following issues:</p>

      {/* Display general errors */}
      {generalErrors.length > 0 && (
        <ul className="mb-0 ps-3">
          {generalErrors.map(({ field, message }) => (
            <li key={field} className="small text-danger">
              {message}
            </li>
          ))}
        </ul>
      )}

      {/* Display participant-specific errors */}
      {Object.entries(participantErrors).map(([index, errors]) => (
        <div key={index}>
          <strong className="mt-2 d-block">
            Participant {parseInt(index) + 1}:
          </strong>
          <ul className="mb-0 ps-3">
            {errors.map(({ field, message }, idx) => (
              <li key={`${field}_${idx}`} className="small text-danger">
                {message}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Alert>
  );
}

export default ErrorAlert;
