// src/components/AssemblyRegistrationForm.jsx

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Accordion,
  Modal,
  Alert,
} from "react-bootstrap";
import ErrorAlert from "./ErrorAlert";
import StepOneForm from "./StepOneForm";
import { sanitizeInput } from "../services/sanitize";
import { memberAreas } from "../services/area";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

function AssemblyRegistrationForm({ event, onClose }) {
  // Define the registration amounts
  const registrationOnlyAmount = 3000; // Adjust as needed
  const registrationWithBedAmount = 4500; // Adjust as needed

  const maxParticipants = 10;

  const { fields } = event.registrable.formData;

  // Define initial organizer data using useMemo
  const initialOrganizerData = useMemo(
    () => ({
      organizerName: { id: uuidv4(), value: "" },
      organizerEmail: { id: uuidv4(), value: "" },
    }),
    []
  );

  // Function to generate initial participant data
  const generateInitialParticipantData = useCallback(
    (num) =>
      Array.from({ length: num }, () => ({
        id: uuidv4(),
        first_name: { id: uuidv4(), value: "" },
        last_name: { id: uuidv4(), value: "" },
        email: { id: uuidv4(), value: "" },
        phone_number: { id: uuidv4(), value: "" },
        is_member: { id: uuidv4(), value: false },
        member_area_id: { id: uuidv4(), value: "" },
        bed_option: { id: uuidv4(), value: "" }, // Add bed_option field
      })),
    []
  );

  // Initialize state variables
  const [organizerData, setOrganizerData] = useState(initialOrganizerData);
  const [numParticipants, setNumParticipants] = useState(2);
  const [participantData, setParticipantData] = useState(() =>
    generateInitialParticipantData(2)
  );
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [validated, setValidated] = useState(false);
  const [isSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [whatsappLink, setWhatsappLink] = useState("");

  // Handle organizer data change
  const handleOrganizerChange = useCallback((e) => {
    const { name, value } = e.target;

    setOrganizerData((prev) => ({
      ...prev,
      [name]: { ...prev[name], value },
    }));

    // Perform real-time validation
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (name === "organizerName") {
        if (value.trim() !== "") {
          delete newErrors.organizerName;
        } else {
          newErrors.organizerName = "Organizer name is required.";
        }
      }
      if (name === "organizerEmail") {
        if (value.trim() === "") {
          newErrors.organizerEmail = "Organizer email is required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim())) {
          newErrors.organizerEmail = "Invalid email format.";
        } else {
          delete newErrors.organizerEmail;
        }
      }
      return newErrors;
    });
  }, []);

  // Handle number of participants change
  const handleNumParticipantsChange = useCallback(
    (e) => {
      const value = parseInt(e.target.value, 10);
      setNumParticipants(value);

      setParticipantData((prevData) => {
        const newData = [...prevData];
        if (value > prevData.length) {
          // Add new empty participant entries with unique IDs
          for (let i = prevData.length; i < value; i++) {
            newData.push({
              id: uuidv4(),
              first_name: { id: uuidv4(), value: "" },
              last_name: { id: uuidv4(), value: "" },
              email: { id: uuidv4(), value: "" },
              phone_number: { id: uuidv4(), value: "" },
              is_member: { id: uuidv4(), value: false },
              member_area_id: { id: uuidv4(), value: "" },
              bed_option: { id: uuidv4(), value: "" }, // Add bed_option field
            });
          }
        } else if (value < prevData.length) {
          // Remove excess participant entries
          newData.length = value;
        }
        return newData;
      });

      // Optionally, clear related errors when changing the number of participants
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        // Remove errors related to participant IDs beyond the new number
        Object.keys(newErrors).forEach((key) => {
          const match = key.match(/_(\w+)$/);
          if (match) {
            const participantId = match[1];
            const participantIndex = participantData.findIndex(
              (p) => p.id === participantId
            );
            if (participantIndex === -1 || participantIndex >= value) {
              delete newErrors[key];
            }
          }
        });
        return newErrors;
      });
    },
    [participantData]
  );

  // Handle participant data change by participant ID
  const handleParticipantChange = useCallback((participantId, e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setParticipantData((prevData) =>
      prevData.map((participant) =>
        participant.id === participantId
          ? {
              ...participant,
              [name]: { ...participant[name], value: inputValue },
            }
          : participant
      )
    );

    // Remove the error for this field
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      const errorKey = `${name}_${participantId}`;
      delete newErrors[errorKey];
      return newErrors;
    });
  }, []);

  // Validate organizer data
  const validateOrganizerData = useCallback(() => {
    let valid = true;
    const newErrors = {};

    // Validate organizer name
    const organizerName = organizerData.organizerName.value.trim();
    if (!organizerName) {
      newErrors.organizerName = "Organizer name is required.";
      valid = false;
    }

    // Validate organizer email
    const organizerEmail = organizerData.organizerEmail.value.trim();
    if (!organizerEmail) {
      newErrors.organizerEmail = "Organizer email is required.";
      valid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(organizerEmail)) {
      newErrors.organizerEmail = "Invalid email format.";
      valid = false;
    }

    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
    return valid;
  }, [organizerData]);

  // Validate participant data
  const validateParticipantData = useCallback(() => {
    let valid = true;
    const newErrors = {};

    participantData.forEach((participant) => {
      fields.forEach((field) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        const fieldValue = participant[fieldName].value.trim();
        const fieldType = field.type === "email" ? "email" : field.type;
        const sanitizedValue = sanitizeInput(fieldValue, fieldType);

        if (!sanitizedValue) {
          valid = false;
          newErrors[
            `${fieldName}_${participant.id}`
          ] = `Invalid characters in ${field.label}.`;
        }

        if (
          field.type === "email" &&
          fieldValue &&
          !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValue)
        ) {
          valid = false;
          newErrors[`${fieldName}_${participant.id}`] = "Invalid email format.";
        }

        if (
          fieldName === "phone_number" &&
          fieldValue &&
          !/^\d{10,15}$/.test(fieldValue)
        ) {
          valid = false;
          newErrors[`${fieldName}_${participant.id}`] =
            "Invalid phone number format. Use 10-15 digits.";
        }

        if (!fieldValue) {
          valid = false;
          newErrors[
            `${fieldName}_${participant.id}`
          ] = `${field.label} is required.`;
        }
      });

      // Additional validations for is_member, member_area_id
      if (participant.is_member.value && !participant.member_area_id.value) {
        valid = false;
        newErrors[`member_area_id_${participant.id}`] =
          "Please select your area.";
      }

      // Validate bed_option
      if (
        !["registration_only", "registration_with_bed"].includes(
          participant.bed_option.value
        )
      ) {
        valid = false;
        newErrors[`bed_option_${participant.id}`] =
          "Please select a registration option.";
      }
    });

    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
    return valid;
  }, [participantData, fields]);

  // Determine if the Organizer Form is Valid
  const isOrganizerFormValid = useCallback(() => {
    return (
      organizerData.organizerName.value.trim() !== "" &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
        organizerData.organizerEmail.value.trim()
      )
    );
  }, [organizerData]);

  // Handle next step in the form
  const handleNext = useCallback(() => {
    const isValidOrganizer = validateOrganizerData();
    setValidated(true);

    if (isValidOrganizer) {
      setCurrentStep(2);
      setSubmitError(null);
    } else {
      setSubmitError("Please fix the errors before proceeding.");
    }
  }, [validateOrganizerData]);

  // Go back to the previous step
  const handleBackButton = useCallback(() => {
    setCurrentStep((prevStep) => prevStep - 1);
    setSubmitError(null);
  }, []);

  // Handle form submission
  const handleSubmit = useCallback(() => {
    const isValidParticipants = validateParticipantData();

    if (!isValidParticipants) {
      setSubmitError("Please fix the errors in participant data.");
      return;
    }

    // Generate WhatsApp link
    const message = generateWhatsAppMessage();
    const whatsappNumber = "2349012201298"; // WhatsApp number without '+'
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    setWhatsappLink(whatsappLink);

    setShowWhatsAppModal(true);
    setSubmitError(null);
  }, [validateParticipantData]);

  // Generate WhatsApp message
  const generateWhatsAppMessage = () => {
    let message = `Registration Details:\n\nOrganizer:\n`;
    message += `Name: ${organizerData.organizerName.value}\n`;
    message += `Email: ${organizerData.organizerEmail.value}\n\n`;

    participantData.forEach((participant, index) => {
      message += `Participant ${index + 1}:\n`;
      fields.forEach((field) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        const fieldLabel = field.label;
        const value = participant[fieldName].value;
        message += `${fieldLabel}: ${value}\n`;
      });

      message += `Are you a CGWC Member?: ${
        participant.is_member.value ? "Yes" : "No"
      }\n`;

      if (participant.is_member.value && participant.member_area_id.value) {
        const selectedArea = memberAreas.find(
          (area) => area.id === participant.member_area_id.value
        );
        message += `Member Area: ${
          selectedArea ? selectedArea.name : participant.member_area_id.value
        }\n`;
      }

      // Include Registration Option
      message += `Registration Option: ${
        participant.bed_option.value === "registration_only"
          ? `Registration Only (₦${registrationOnlyAmount.toLocaleString()})`
          : participant.bed_option.value === "registration_with_bed"
          ? `Registration with Bed (₦${registrationWithBedAmount.toLocaleString()})`
          : "Not selected"
      }\n`;

      message += `\n`;
    });

    return message;
  };

  // Save state to localStorage whenever relevant data changes
  useEffect(() => {
    localStorage.setItem("organizerData", JSON.stringify(organizerData));
    localStorage.setItem("participantData", JSON.stringify(participantData));
    localStorage.setItem("currentStep", currentStep);
    localStorage.setItem("numParticipants", numParticipants);
  }, [organizerData, participantData, currentStep, numParticipants]);

  // Restore state from localStorage on component mount
  useEffect(() => {
    const savedOrganizerData = localStorage.getItem("organizerData");
    const savedParticipantData = localStorage.getItem("participantData");
    const savedCurrentStep = localStorage.getItem("currentStep");
    const savedNumParticipants = localStorage.getItem("numParticipants");

    if (savedOrganizerData) {
      setOrganizerData(JSON.parse(savedOrganizerData));
    }

    if (savedParticipantData) {
      setParticipantData(JSON.parse(savedParticipantData));
    }

    if (savedCurrentStep) {
      setCurrentStep(parseInt(savedCurrentStep, 10));
    }

    if (savedNumParticipants) {
      setNumParticipants(parseInt(savedNumParticipants, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle closing the modal and resetting the form
  const handleCloseModal = () => {
    setShowWhatsAppModal(false);
    setOrganizerData(initialOrganizerData);
    setParticipantData(generateInitialParticipantData(2)); // Reset to 2 participants
    setErrors({});
    setCurrentStep(1);
    setNumParticipants(2);
    localStorage.removeItem("organizerData");
    localStorage.removeItem("participantData");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("numParticipants");
    onClose();
  };

  return (
    <>
      {submitError && <ErrorAlert errors={{ submitError }} />}

      {/* Step 1: Organizer Information */}
      {currentStep === 1 && (
        <Container>
          <Form noValidate validated={validated}>
            <h3 className="text-primary mb-4 text-center">
              Organizer Information
            </h3>
            <Row className="justify-content-center">
              <Col xs={12} md={8}>
                <Form.Group controlId="organizerName" className="mb-3">
                  <Form.Label>
                    Organizer Name <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="organizerName"
                    value={organizerData.organizerName.value}
                    onChange={handleOrganizerChange}
                    isInvalid={!!errors.organizerName}
                    required
                    placeholder="Enter your full name"
                    aria-required="true"
                    aria-label="Organizer Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.organizerName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={8}>
                <Form.Group controlId="organizerEmail" className="mb-3">
                  <Form.Label>
                    Organizer Email <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="organizerEmail"
                    value={organizerData.organizerEmail.value}
                    onChange={handleOrganizerChange}
                    isInvalid={!!errors.organizerEmail}
                    required
                    placeholder="Enter your email address"
                    aria-required="true"
                    aria-label="Organizer Email"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.organizerEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={8}>
                <Form.Group controlId="numParticipants" className="mb-3">
                  <Form.Label>
                    Number of Participants{" "}
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="numParticipants"
                    value={numParticipants}
                    onChange={handleNumParticipantsChange}
                    aria-label="Select number of participants"
                  >
                    {[...Array(maxParticipants - 1).keys()].map((num) => (
                      <option key={num + 2} value={num + 2}>
                        {num + 2}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex justify-content-end mt-4">
              <Button
                variant="primary"
                onClick={handleNext}
                disabled={isSubmitting || !isOrganizerFormValid()}
                type="button"
                aria-label="Proceed to Participant Information"
              >
                Next
              </Button>
            </div>
          </Form>
        </Container>
      )}

      {/* Step 2: Participant Information */}
      {currentStep === 2 && (
        <Container>
          <h3 className="text-primary mb-4 text-center">
            Participant Information
          </h3>
          <Accordion defaultActiveKey="0">
            {participantData.map((participant, index) => {
              const isComplete =
                !fields.some((field) => {
                  const fieldName = field.label
                    .toLowerCase()
                    .replace(/ /g, "_");
                  return !participant[fieldName].value;
                }) && participant.bed_option.value;

              return (
                <Accordion.Item eventKey={`${index}`} key={participant.id}>
                  <Accordion.Header>
                    <div className="d-flex align-items-center">
                      <span className="me-2">Participant {index + 1}</span>
                      {isComplete && (
                        <span className="badge bg-success ms-2">Complete</span>
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <StepOneForm
                      participantId={participant.id}
                      formData={participant}
                      errors={Object.fromEntries(
                        Object.entries(errors).filter(([key]) =>
                          key.endsWith(`_${participant.id}`)
                        )
                      )}
                      handleChange={(e) =>
                        handleParticipantChange(participant.id, e)
                      }
                      uniqueId={participant.id}
                      isSubmitting={isSubmitting}
                      fields={fields}
                      memberAreas={memberAreas}
                      requiredLabel={(field) =>
                        field.label !== "Are you a CGWC Member"
                      }
                      hideNextButton={true}
                      registrationOnlyAmount={registrationOnlyAmount}
                      registrationWithBedAmount={registrationWithBedAmount}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>

          <div className="d-flex justify-content-between mt-4">
            <Button
              variant="secondary"
              onClick={handleBackButton}
              disabled={isSubmitting}
              aria-label="Go Back to Organizer Information"
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
              aria-label="Submit Registration"
            >
              Submit
            </Button>
          </div>
        </Container>
      )}

      {/* WhatsApp Modal */}
      <Modal
        show={showWhatsAppModal}
        onHide={() => setShowWhatsAppModal(false)}
        centered
        aria-labelledby="whatsapp-modal-title"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#042556", color: "#fff" }}
        >
          <Modal.Title id="whatsapp-modal-title">
            Registration Step 1 Complete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">
            The first stage of your registration is complete. Kindly click this
            WhatsApp button to submit your registration and follow the steps
            after.
          </Alert>
          <div className="d-flex justify-content-center">
            <Button
              variant="success"
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Submit via WhatsApp"
            >
              Submit via WhatsApp
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCloseModal}
            aria-label="Close Modal"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

AssemblyRegistrationForm.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AssemblyRegistrationForm;
