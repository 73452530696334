// src/components/IndividualStepOneForm.jsx

import React from "react";
import { Form } from "react-bootstrap";
import ErrorAlert from "./ErrorAlert";

function IndividualStepOneForm({
  formData,
  errors,
  handleChange,
  fields,
  memberAreas,
  requiredLabel,
  registrationOnlyAmount,
  registrationWithBedAmount,
}) {
  return (
    <>
      {Object.keys(errors).some((key) => errors[key]) && (
        <ErrorAlert errors={errors} />
      )}

      {/* Render dynamically generated fields */}
      {fields.map((field, index) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        return (
          <Form.Group controlId={fieldName} key={index} className="mb-3">
            <Form.Label>
              {field.label}{" "}
              {requiredLabel(field) && <span style={{ color: "red" }}> *</span>}
            </Form.Label>
            <Form.Control
              type={field.type}
              name={fieldName}
              value={formData[fieldName] || ""}
              onChange={handleChange}
              isInvalid={!!errors[fieldName]}
              required={field.required}
            />
            <Form.Control.Feedback type="invalid">
              {errors[fieldName]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      })}

      {/* Checkbox for CGWC Membership Status */}
      <Form.Group controlId="is_member" className="mb-3">
        <Form.Check
          type="checkbox"
          label="Are you a CGWC Member?"
          name="is_member"
          checked={formData.is_member || false}
          onChange={(e) =>
            handleChange({
              target: { name: "is_member", checked: e.target.checked },
            })
          }
          isInvalid={!!errors.is_member}
        />
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.is_member}
        </Form.Control.Feedback>
      </Form.Group>

      {/* Conditional Area Selection if is_member is true */}
      {formData.is_member && (
        <Form.Group controlId="member_area_id" className="mb-3">
          <Form.Label>Select Your Area</Form.Label>
          <Form.Control
            as="select"
            name="member_area_id"
            value={formData.member_area_id || ""}
            onChange={handleChange}
            isInvalid={!!errors.member_area_id}
            required
          >
            <option value="">-- Select Area --</option>
            {memberAreas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.member_area_id}
          </Form.Control.Feedback>
        </Form.Group>
      )}

      {/* Registration Option for bed_option */}
      <Form.Group controlId="bed_option" className="mb-3">
        <Form.Label>Registration Option</Form.Label>
        <div>
          <Form.Check
            type="radio"
            label={`Registration Only (₦${registrationOnlyAmount.toLocaleString()})`}
            name="bed_option"
            id="registration_only"
            value="registration_only"
            checked={formData.bed_option === "registration_only"}
            onChange={(e) => handleChange(e)}
            required
          />
          <Form.Check
            type="radio"
            label={`Registration with Bed (₦${registrationWithBedAmount.toLocaleString()})`}
            name="bed_option"
            id="registration_with_bed"
            value="registration_with_bed"
            checked={formData.bed_option === "registration_with_bed"}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        {errors.bed_option && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.bed_option}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
}

export default IndividualStepOneForm;
